const location = {
  translations: {
    "User": "User",
    "Register your account": "Register your account",
    "Let's start with the user account": "Let's start with the user account",
    "Fill in your email": "Fill in your email that you use professionally, full name of individual and your cell phone number",
    "Email": "Email",
    "Full name": "Full name",
    "Phone": "Phone",
    "I have read and": "I have read and agree to the User Agreement",
    "Register": "Register",
    "Validation": "Validation",
    "Account validation": "Account validation",
    "Validation sent to your mobile": "Validation sent to your mobile",
    "We sent a message": "We sent an SMS message with the confirmation code to the cell phone below:",
    "Enter the code": "Enter the verification code",
    "Didn't receive": "Didn't receive the code? Click to resend",
    "Confirm": "Confirm",
    "Back": "Back",
    "Register your company": "Register your company",
    "Now register as": "Now register your company information to create your customized business account for your business",
    "Choose the segment": "Choose the segment of your company",
    "Internet providers": "Internet providers",
    "Other segments": "Other segments",
    "Company type": "Company type",
    "Social reason": "Social reason",
    "Address": "Address",
    "Number": "Number",
    "Complement": "Complement",
    "Neighborhood": "Neighborhood",
    "Select company type": "Select company type",
    "Send the documents": "Send the documents",
    "To complete the process": "To complete the registration process, you must send the company's documents and your identification as responsible for the company.",
    "Send the documents below": "Send the documents below",
    "Social contract": "Social contract",
    "Send the complete articles of incorporation of the company": "Send the complete articles of association of the company",
    "RG or CNH": "RG or CNH",
    "Send a copy of the natural person document": "Send a copy of the natural person document",
    "Send": "Send",
    "I am not the person in charge or partner of the company": "I am not the person in charge or partner of the company",
    "Selfie with document": "Selfie with document",
    "Take a selfie holding ID": "Take a selfie holding ID",
    "Success!": "Success!",
    "Let's start with the user account!": "Let's start with the user account!",
    "Wait, our team is validating the entered data.": "Wait, our team is validating the entered data.",
    "We will send the confirmation as soon as possible to your email.": "We will send the confirmation as soon as possible to your email.",
    "An email with next steps has been sent": "An email with next steps has been sent",
    "Company": "Company",
    "Documents": "Documents",
    "Confirmation": "Confirmation",
    "Add all documents to continue": "Add all documents to continue",
    "Code resent to email provided :)": "Code resent to email provided :)",
    "Error sending social contract": "Error sending social contract",
    "Error sending RG or CNH": "Error sending RG or CNH",
    "Error sending selfie with document": "Error sending selfie with document",
    "Invalid code :(": "Invalid code :(",
  }
}

export default location  